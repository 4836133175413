'use strict';

/**
 * Removes the footer when the close button is clicked
 */
function toggleStickyFooter() {
    $('.content-pages').addClass('d-none');
    const date = new Date();
    date.setTime(date.getTime() + (60 * 30 * 1000));
    let expires = 'expires=' + date.toUTCString();
    document.cookie = 'isClosed=' + true + ';' + expires + ';path=/'; // adds a cookie to the client to keep the footer removed for a certain time
}

$(document).ready(function () {
    $('.stickyClosebutton').on('click', toggleStickyFooter);
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookies = decodedCookie.split(';');
    let value;
    for (let i = 0; i < cookies.length; i++) { // checks the cookies incase the is closed cookie is active in the client.
        let cookie = cookies[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf('isClosed') === 0) {
            value = cookie.substring('isClosed'.length + 1, cookie.length);
        }
    }
    if (value) $('.content-pages').addClass('d-none');
    else $('.content-pages').removeClass('d-none');
});
